export const dateFormatter = (timestamp, onlyDate) => {
  try {
    if (!timestamp) {
      return;
    }
    const date = new Date(timestamp);

    const timeZoneOffset = date.getTimezoneOffset();
    const timeZone = timeZoneOffset === 0 ? "UTC" : "Asia/Kolkata";

    let options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      timeZone,
    };
    if (!onlyDate) {
      options.hour = "2-digit"
      options.minute = "2-digit"
    }
    return date.toLocaleString("en-IN", options);
  } catch (error) {
    console.log(error);
    return "N/A";
  }
};
