import PropTypes from "prop-types";
import MDInput from "components/MDInput";

function FormField({
  label,
  value,
  nameSetter,
  lead,
  setLead,
  field,
  updateLeadFun,
  updateLeadfeesFun,
  ...rest
}) {
  return (
    <MDInput
      value={value}
      variant="standard"
      label={label}
      fullWidth
      InputLabelProps={{ shrink: true }}
      onChange={(e) => {
        if (nameSetter) {
          nameSetter(e.target.value, field);
        } else if (updateLeadfeesFun) {
          updateLeadfeesFun(field, e.target.value);
        } else {
          setLead({ ...lead, [field]: e.target.value });
          updateLeadFun && updateLeadFun(null, { ...lead, [field]: e.target.value });
        }
      }}
      {...rest}
    />
  );
}

// Setting default values for the props of FormField
FormField.defaultProps = {
  label: " ",
};

// Typechecking props for FormField
FormField.propTypes = {
  label: PropTypes.string,
};

export default FormField;
