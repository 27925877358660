import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { RESPONSE_COLORS } from "../../../config";
import { useEffect, useState } from "react";
import Call from "./Call";
import { useMaterialUIController } from "context";
import { Grid } from "@mui/material";
import MDButton from "components/MDButton";

function Calls({ lead, setLead }) {
  const [controller] = useMaterialUIController();
  const { openedLeadDetail } = controller;
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  function pagination(pageNumber, pageSize) {
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    setLead({
      ...lead,
      calls: openedLeadDetail.calls.slice().reverse()?.slice(startIndex, endIndex),
    });
    setPageNumber(1);
    setPageSize(pageSize + 5);
  }

  useEffect(() => {
    if (openedLeadDetail && lead?.calls) {
      pagination(pageNumber, pageSize);
    }
  }, []);

  return (
    <Card id="calls">
      <MDBox p={3} lineHeight={1}>
        <MDBox mb={1}>
          <MDTypography variant="h5">Calls</MDTypography>
        </MDBox>
        <MDTypography variant="button" color="text" fontWeight="regular">
          {lead?.calls?.length > 0 ? "This is a list of calls." : "No calls"}
        </MDTypography>
      </MDBox>
      <MDBox>
        {lead?.calls?.length > 0 &&
          lead?.calls?.map((call) => (
            <MDBox pb={1} px={3} sx={{ overflow: "auto" }} key={call._id}>
              <Call
                time={call?.time || call?.startTime || Date.now()}
                remark={call?.remark}
                result={call?.result}
                color={RESPONSE_COLORS[call?.result?.toLowerCase()]}
                duration={call?.duration}
                ringDuration={call?.ringDuration}
              />
              <Divider />
            </MDBox>
          ))}
        <Grid item xs={12}>
          <MDBox display="flex" flexDirection="column" alignItems="end" mr={2} mb={2}>
            <MDButton
              variant="gradient"
              color="info"
              sx={{ height: "100%" }}
              onClick={() => pagination(pageNumber, pageSize)}
            >
              more calls
            </MDButton>
          </MDBox>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Calls;
