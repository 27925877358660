import { Grid } from "@mui/material";
import { createLead } from "apis/lead";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import LeadsLayout from "examples/LayoutContainers/LeadsLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useState } from "react";
import { ColorRing } from "react-loader-spinner";
import { clearErrorMessage } from "utils/clearErrorMessage";

const CreateLead = () => {
  const [lead, setLead] = React.useState({
    lead_id: "",
    ad_id: "",
    ad_name: "",
    adset_id: "",
    adset_name: "",
    campaign_id: "",
    campaign_name: "",
    form_id: "",
    form_name: "",
    paid: "",
    source: "",
    name: "",
    email: "",
    phone: "",
    state: "",
    stage: "New Lead",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const openSuccess = () => setSuccess(true);
  const closeSuccess = () => setSuccess(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLead({ ...lead, [name]: value });
  };

  const addLead = async () => {
    try {
      if (!lead.phone) {
        alert("Please enter phone number");
      } else {
        setLoading(true);
        const res = await createLead(lead, localStorage.getItem("user-secret"));
        if (res.status === 200) {
          setLead({
            lead_id: "",
            ad_id: "",
            ad_name: "",
            adset_id: "",
            adset_name: "",
            campaign_id: "",
            campaign_name: "",
            form_id: "",
            form_name: "",
            paid: "",
            source: "",
            name: "",
            email: "",
            phone: "",
            state: "",
            stage: "New Lead",
          });
          openSuccess();
        } else {
          setError(res.error.message);
        }
        setLoading(false);
        clearErrorMessage(5000, setError);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderSuccess = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Lead Created Successfully"
      content={`Lead creation was successful for ${lead.phone}`}
      dateTime="1 seconds ago"
      open={success}
      onClose={closeSuccess}
      close={closeSuccess}
      bgWhite
      autoHide={true}
    />
  );

  return (
    <LeadsLayout>
      <DashboardNavbar />
      <MDTypography variant="h5" fontWeight="medium" mb={4}>
        Create Lead
      </MDTypography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <MDInput
            name="lead_id"
            value={lead.lead_id}
            type="text"
            label="Lead ID"
            variant="standard"
            fullWidth
            placeholder="823180912574535"
            InputLabelProps={{ shrink: true }}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MDInput
            name="ad_id"
            value={lead.ad_id}
            type="text"
            label="Ad ID"
            variant="standard"
            fullWidth
            placeholder="123456"
            InputLabelProps={{ shrink: true }}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MDInput
            name="ad_name"
            value={lead.ad_name}
            type="text"
            label="Ad Name"
            variant="standard"
            fullWidth
            placeholder="Ad Name"
            InputLabelProps={{ shrink: true }}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MDInput
            name="adset_id"
            value={lead.adset_id}
            type="text"
            label="Adset ID"
            variant="standard"
            fullWidth
            placeholder="78910"
            InputLabelProps={{ shrink: true }}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MDInput
            name="adset_name"
            value={lead.adset_name}
            type="text"
            label="Adset Name"
            variant="standard"
            fullWidth
            placeholder="Adset Name"
            InputLabelProps={{ shrink: true }}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MDInput
            name="campaign_id"
            value={lead.campaign_id}
            type="text"
            label="Campaign ID"
            variant="standard"
            fullWidth
            placeholder="3"
            InputLabelProps={{ shrink: true }}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MDInput
            name="campaign_name"
            value={lead.campaign_name}
            type="text"
            label="Campaign Name"
            variant="standard"
            fullWidth
            placeholder="Campaign Name"
            InputLabelProps={{ shrink: true }}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MDInput
            name="form_id"
            value={lead.form_id}
            type="text"
            label="Form ID"
            variant="standard"
            fullWidth
            placeholder="Form ID"
            InputLabelProps={{ shrink: true }}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MDInput
            name="form_name"
            value={lead.form_name}
            type="text"
            label="Form Name"
            variant="standard"
            fullWidth
            placeholder="Form Name"
            InputLabelProps={{ shrink: true }}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MDInput
            name="paid"
            value={lead.paid}
            type="text"
            label="Paid"
            variant="standard"
            fullWidth
            placeholder="Yes/No"
            InputLabelProps={{ shrink: true }}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MDInput
            name="source"
            value={lead.source}
            type="text"
            label="Source"
            variant="standard"
            fullWidth
            placeholder="Source"
            InputLabelProps={{ shrink: true }}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MDInput
            name="name"
            value={lead.name}
            type="text"
            label="Full Name"
            variant="standard"
            fullWidth
            placeholder="John Doe"
            InputLabelProps={{ shrink: true }}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MDInput
            name="email"
            value={lead.email}
            type="email"
            label="Email"
            variant="standard"
            fullWidth
            placeholder="john@example.com"
            InputLabelProps={{ shrink: true }}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MDInput
            name="phone"
            value={lead.phone}
            type="text"
            label="Phone"
            variant="standard"
            fullWidth
            placeholder="123-456-7890"
            InputLabelProps={{ shrink: true }}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MDInput
            name="state"
            value={lead.state}
            type="text"
            label="State"
            variant="standard"
            fullWidth
            placeholder="State"
            InputLabelProps={{ shrink: true }}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MDInput
            name="stage"
            value={lead.stage}
            type="text"
            label="Stage"
            variant="standard"
            fullWidth
            placeholder="New Lead"
            InputLabelProps={{ shrink: true }}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      {renderSuccess}
      {error && (
        <MDBox>
          <p style={{ color: "red", textAlign: "center" }}>{error && error}</p>
        </MDBox>
      )}
      <MDBox mt={2} display={"flex"}>
        <MDButton
          size="large"
          color={"success"}
          style={{ marginLeft: "auto" }}
          onClick={() => addLead()}
        >
          {loading ? (
            <ColorRing
              visible={true}
              height="20"
              width="30"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
            />
          ) : (
            "Create Lead"
          )}
        </MDButton>
      </MDBox>
    </LeadsLayout>
  );
};

export default CreateLead;
