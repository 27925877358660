import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { getLeads } from "apis/lead";
import { ColorRing } from "react-loader-spinner";
import LeadModal from "layouts/modals/LeadModal";
import DataTable from "./DataTable";
import { dateFormatter } from "utils/dateFormatter";
import { useMaterialUIController, setLeads } from "context";
import { getFollowUps } from "apis/lead";
import { getTodayDemos } from "apis/lead";
import MDInput from "components/MDInput";
import { getNotPicked } from "apis/lead";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import LeadOwnerChangeModal from "layouts/modals/LeadOwnerChangeModal";
import { getLeadsWithCompletedFees } from "apis/lead";
import { getLeadsWithPendingFees } from "apis/lead";
import { getOldFollowUps } from "apis/lead";

function LeadTables({ leadType, heading }) {
  const [showLeadModal, setShowLeadModal] = useState(false);
  const [lead, setLead] = useState("");
  const [nextCallTimeNotSet, setNextCallTimeNotSet] = useState([]);
  const [switchTable, setSwitchTable] = useState(false);
  const [refreshLead, steRefreshLead] = useState("");
  const [controller, dispatch] = useMaterialUIController();
  const { leads } = controller;
  const [token] = useState(localStorage.getItem("user-secret") || null);
  const [searchLead, setSearchLead] = useState(null);
  const [searchString, setSearchString] = useState("");
  const [limit, setLimit] = useState(100);
  const [allFetched, setAllFetched] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedStage, setSelectedStage] = useState("");
  const [tempLeads, setTempLeads] = useState(null);
  const [showChangeOwnerModal, setShowChangeOwnerModal] = useState(false);
  const [updateOwnerLead, setUpdateOwnerLead] = useState(null);

  const dataTable = {
    columns: [
      { Header: "name", accessor: "name", width: "20%" },
      { Header: "email", accessor: "email", width: "25%" },
      {
        Header: "phone number",
        accessor: (row) => row?.mobile || row?.phone,
      },

      { Header: "created date", accessor: (row) => dateFormatter(row?.created) },
      {
        Header: "last call",
        accessor: (row) => dateFormatter(row?.calls[row?.calls.length - 1]?.startTime) || "",
      },
      { Header: "next call", accessor: (row) => dateFormatter(row.nextCall) },
    ],

    rows: searchString || selectedStage ? searchLead : switchTable ? nextCallTimeNotSet : leads,
  };

  const fetchLeadsByType = async (all) => {
    const leadsLimit = all || tempLeads?.length + 100 || limit;
    const leadTypeMap = {
      FOLLOWUPS: {
        defaultLeads: tempLeads?.length ? tempLeads : null,
        fetchFunction: getFollowUps,
      },
      DEMOSCHEDULE: {
        defaultLeads: tempLeads?.length ? tempLeads : null,
        fetchFunction: getTodayDemos,
      },
      NOT_PICKED: {
        defaultLeads: tempLeads?.length ? tempLeads : null,
        fetchFunction: getNotPicked,
      },
      LEADS_WITH_COMPLETED_FEES: {
        defaultLeads: tempLeads?.length ? tempLeads : null,
        fetchFunction: getLeadsWithCompletedFees,
      },
      LEADS_WITH_PENDING_FEES: {
        defaultLeads: tempLeads?.length ? tempLeads : null,
        fetchFunction: getLeadsWithPendingFees,
      },
      OLD_FOLLOW_UPS: {
        defaultLeads: tempLeads?.length ? tempLeads : null,
        fetchFunction: getOldFollowUps,
      },
    };
    const leadConfig = leadTypeMap[leadType];

    if (leadConfig) {
      setLeads(dispatch, "LEADS", leadConfig.defaultLeads);
      try {
        setLoader(true);
        const response = await leadConfig.fetchFunction(token, leadsLimit);
        if (response && response.status === 200) {
          if (!response.data.leads.length) {
            setAllFetched(true);
            if (!leads) {
              setTempLeads((prev) => [...(prev || []), ...response.data.leads]);
            }
          } else {
            setTempLeads((prev) =>
              all ? response.data.leads : [...(prev || []), ...response.data.leads]
            );
            if (all) {
              setAllFetched(true);
            } else {
              setLimit(tempLeads?.length + 100);
              setAllFetched(false);
            }
          }
        }
        setLoader(false);
      } catch (error) {
        console.error("Error fetching leads:", error);
      }
    } else {
      console.warn("Unknown lead type:", leadType);
    }
  };

  async function fetchLeadsData(all) {
    const leadsLimit = all || tempLeads?.length + 100 || limit;
    setLoader(true);
    const response = await getLeads(token, leadsLimit);
    if (response && response.status === 200) {
      if (!response.data.leads.length) {
        setAllFetched(true);
        setTempLeads((prev) => [...(prev || []), ...response.data.leads]);
      } else {
        setTempLeads((prev) =>
          all ? response.data.leads : [...(prev || []), ...response.data.leads]
        );
        setNextCallTimeNotSet(
          response.data.leads.filter(
            (obj) =>
              (!obj.hasOwnProperty("nextCall") || !obj.nextCall) &&
              obj.stage.toLowerCase() !== "new lead" &&
              obj.stage.toLowerCase() !== "not interested" &&
              obj.stage.toLowerCase() !== "registered"
          )
        );
        if (all) {
          setAllFetched(true);
        } else {
          setLimit(tempLeads?.length + 100);
          setAllFetched(false);
        }
      }
      setLoader(false);
    } else {
      console.log(response.error.message);
    }
  }

  const handleModal = (lead) => {
    if (lead) {
      setLead(lead);
    }
    if (showLeadModal) {
      setTempLeads([]);
      steRefreshLead(Date.now());
    }
    setShowLeadModal(!showLeadModal);
  };

  const handleSwitchTable = () => {
    setSwitchTable(!switchTable);
  };

  const searchLeadHandler = (searchString) => {
    try {
      setSelectedStage("");
      setSearchString(searchString);
      if (!leads) {
        setSearchLead([]);
      } else {
        const searchResult = leads.filter((lead) => {
          if (
            lead?.mobile?.toLowerCase().includes(searchString.toLowerCase()) ||
            lead?.phone?.toLowerCase().includes(searchString.toLowerCase()) ||
            lead?.name?.toLowerCase().includes(searchString.toLowerCase()) ||
            lead?.email?.toLowerCase().includes(searchString.toLowerCase())
          ) {
            return lead;
          }
        });
        setSearchLead(searchResult);
      }
    } catch (error) {
      console.log("--", error.message);
    }
  };

  const handleStageChange = (event) => {
    try {
      setSearchString("");
      setSelectedStage(event.target.value);
      if (!leads) {
        setSearchLead([]);
      } else {
        if (event.target.value.toLowerCase() === "all") {
          // setSearchLead([...leads]);
        }
        const searchResult = leads.filter((lead) => {
          if (lead?.stage?.toLowerCase().includes(event.target.value.toLowerCase())) {
            return lead;
          }
        });
        setSearchLead(searchResult);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOwnerChangeModal = (value, refresh) => {
    if (value) {
      setUpdateOwnerLead(value);
      setShowChangeOwnerModal(!showChangeOwnerModal);
    } else if (refresh) {
      steRefreshLead(Date.now());
      setShowChangeOwnerModal(!showChangeOwnerModal);
    } else {
      setShowChangeOwnerModal(!showChangeOwnerModal);
    }
  };

  useEffect(() => {
    setTempLeads(null);
    setLimit(100);
    if (!leadType) {
      setLeads(dispatch, "LEADS", null);
      fetchLeadsData();
    } else {
      fetchLeadsByType();
    }
    return () => {
      setLeads(dispatch, "LEADS", null);
      setTempLeads(null);
      setLimit(100);
    };
  }, [refreshLead]);

  useEffect(() => {
    setLeads(dispatch, "LEADS", tempLeads?.length >= 0 ? tempLeads : null);
  }, [tempLeads]);

  return (
    <Card>
      {showLeadModal && <LeadModal open={showLeadModal} handleModal={handleModal} lead={lead} />}
      {showChangeOwnerModal && updateOwnerLead && (
        <LeadOwnerChangeModal
          open={showChangeOwnerModal}
          handleModal={handleOwnerChangeModal}
          updateOwnerLead={updateOwnerLead}
          setUpdateOwnerLead={setUpdateOwnerLead}
        />
      )}
      <MDBox p={3} lineHeight={1} display={"flex"} justifyContent={"space-between"}>
        <MDTypography variant="h5" fontWeight="medium">
          {heading}
        </MDTypography>
        <MDBox
          sx={{
            display: {
              xs: "block", // block for extra small screens
              sm: "flex", // flex for small screens and up
            },
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ minWidth: 120, height: 100 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedStage}
                label="Stage"
                onChange={handleStageChange}
                style={{ height: 43, marginRight: 10 }}
              >
                {/* <MenuItem value={"all"}>All</MenuItem> */}
                <MenuItem value={"New Lead"}>New Lead</MenuItem>
                <MenuItem value={"Initiated"}>Initiated</MenuItem>
                <MenuItem value={"Shared Details"}>Shared Details</MenuItem>
                <MenuItem value={"Demo Scheduled"}>Demo Scheduled</MenuItem>
                <MenuItem value={"Demo Completed"}>Demo Completed</MenuItem>
                <MenuItem value={"Converted"}>Converted</MenuItem>
                <MenuItem value={"Not Interested"}>Not Interested</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <MDInput
            value={searchString}
            InputLabelProps={{ shrink: true }}
            label="Search here"
            onChange={(e) => searchLeadHandler(e.target.value)}
          />
        </MDBox>
      </MDBox>
      {!leads ? (
        <ColorRing
          visible={true}
          height="80"
          width="80"
          ariaLabel="color-ring-loading"
          wrapperStyle={{}}
          wrapperClass="color-ring-wrapper"
          colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
        />
      ) : (
        <>
          {!switchTable ? (
            <DataTable
              table={dataTable}
              handleModal={handleModal}
              nextCallTimeNotSet={nextCallTimeNotSet}
              handleSwitchTable={handleSwitchTable}
              fetchMoreLeadsData={leadType ? fetchLeadsByType : fetchLeadsData}
              allFetched={allFetched}
              loader={loader}
              handleOwnerChangeModal={handleOwnerChangeModal}
            />
          ) : (
            <DataTable
              table={dataTable}
              handleModal={handleModal}
              nextCallTimeNotSet={nextCallTimeNotSet}
              handleSwitchTable={handleSwitchTable}
              back={true}
            />
          )}
        </>
      )}
    </Card>
  );
}

export default LeadTables;
