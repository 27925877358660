import Footer from "examples/Footer";
import LeadsLayout from "examples/LayoutContainers/LeadsLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useState } from "react";
import LeadTables from "../../../../client/src/layouts/leads/components/Table";
import { useLocation } from "react-router-dom";

const Leads = () => {
  const location = useLocation();
  const [leadType] = useState(location?.state?.leadType || "");
  const [heading] = useState(location?.state?.heading || "Leads");
  return (
    <LeadsLayout>
      <DashboardNavbar />
      <LeadTables leadType={leadType} heading={heading} />
      <Footer />
    </LeadsLayout>
  );
};

export default Leads;
