import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import { ColorRing } from "react-loader-spinner";

export default function Logout() {
  const [loading, setLoading] = useState(true);

  const logout = () => {
    try {
      localStorage.removeItem("user-secret");
      setLoading(false);
      window.location.pathname = "/";
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    logout();
  }, []);

  return (
    <>
      {loading && (
        <MDBox display="flex" justifyContent="center" alignItems="center" height="100vh">
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="color-ring-loading"
            wrapperStyle={{}}
            wrapperClass="color-ring-wrapper"
            colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
          />
        </MDBox>
      )}
    </>
  );
}
