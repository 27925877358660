import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import { callTimer } from "utils/callTimer";
import {
  useMaterialUIController,
  setCallingTimer,
  setActiveCallRemark,
  setOpenedLeadDetail,
} from "../../../context";
import { updateLead } from "apis/lead";
import { Grid, Modal } from "@mui/material";
import Calendar from "./Calendar";
import { timeCalculator } from "utils/timeCalculator";
import { NOT_PICKED_RESPONSE } from "config";
import breakpoints from "assets/theme/base/breakpoints";
import { ColorRing } from "react-loader-spinner";
import { nextCallDateTimeSetter } from "utils/nextCallDateTimeSetter";

const CallingButtons = ({ close, lead, token, setLead, updateLeadFun }) => {
  const [controller, dispatch] = useMaterialUIController();
  const { callingTimer, activeCallRemark } = controller;
  const [ringing, setRinging] = useState(false);
  const [picked, setPicked] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [showNextCallModal, setShowNextCallModal] = useState(false);
  const [showNotPickedModal, setShowNotPickedModel] = useState(false);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [submit, setSubmit] = useState(false);
  const [loaderValue, setLoaderValue] = useState("");
  const [endCallLoader, setEndCallLoader] = useState(false);

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("horizontal")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleCallButtonClick = (flag) => {
    if (flag && lead.stage.toLowerCase() === "new lead") {
      updateLead({ lead: { ...lead, stage: "Initiated", previousStage: lead.stage } }, token)
        .then((response) => {
          setLead(response.data.lead);
        })
        .catch((e) => {
          console.log(e);
        });
    }
    setActiveCallRemark(dispatch, "ACTIVE_CALL_REMARK", {
      ...activeCallRemark,
      startTime: Date.now(),
    });
    if (!ringing) {
      if (callingTimer.time !== 0) {
        alert("Please end previous call");
      } else {
        setRinging(true);
      }
    } else if (!picked) {
      callTimer(0, 0, setCallingTimer, dispatch, lead, callingTimer);
      const ringDuration = timeCalculator(activeCallRemark.startTime, Date.now());
      setActiveCallRemark(dispatch, "ACTIVE_CALL_REMARK", {
        ...activeCallRemark,
        ringDuration,
        callStartTime: Date.now(),
      });
      setPicked(true);
      setRinging(false);
    }
  };

  const endCall = async () => {
    const endTime = Date.now();
    callTimer(0, 0, setCallingTimer, dispatch, lead, callingTimer, true);
    const totalDuration = timeCalculator(activeCallRemark.callStartTime, endTime);
    setActiveCallRemark(dispatch, "ACTIVE_CALL_REMARK", {
      ...activeCallRemark,
      duration: totalDuration,
      endTime,
    });
    setRinging(false);
    setPicked(false);
    setSubmit(true);
  };

  const callNotPicked = async () => {
    const endTime = Date.now();
    const timeCalculation = timeCalculator(activeCallRemark.startTime, endTime);
    setActiveCallRemark(dispatch, "ACTIVE_CALL_REMARK", {
      ...activeCallRemark,
      ringDuration: timeCalculation,
      endTime,
    });
    setRinging(false);
    setCancel(true);
  };

  const handleShowNextCallModal = () => {
    setShowNextCallModal(!showNextCallModal);
  };

  const updateLeadNextCall = async (nextCallTime, updatedLead) => {
    try {
      if (!nextCallTime) {
        const date = new Date(Date.now() + 4 * 60 * 60 * 1000);
        const nextCallDateTime = await nextCallDateTimeSetter(date);
        updateLead(
          {
            lead: {
              ...updatedLead,
              nextCall: nextCallDateTime,
            },
          },
          token
        );
      } else {
        await updateLead(
          {
            lead: {
              ...lead,
              nextCall: nextCallTime,
            },
          },
          token
        );
        handleShowNextCallModal();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleShowNotPickedCallModal = () => {
    setShowNotPickedModel(!showNotPickedModal);
  };

  const notFickedUpdateFun = async (newValue) => {
    try {
      setRinging(false);
      setLoaderValue(newValue);
      const { calls } = lead;
      const date = new Date(Date.now() + 4 * 60 * 60 * 1000);
      const nextCallDateTime = await nextCallDateTimeSetter(date);
      const res = await updateLead(
        {
          lead: {
            ...lead,
            calls: [
              ...calls,
              {
                sequence: calls.length,
                result: newValue,
                startTime: activeCallRemark.startTime,
                remark: "",
                duration: "",
                ringDuration: activeCallRemark.ringDuration,
                endTime: activeCallRemark.endTime,
              },
            ],
            nextCall: nextCallDateTime,
          },
        },
        token
      );
      setActiveCallRemark(dispatch, "ACTIVE_CALL_REMARK", {
        result: "",
        startTime: "",
        remark: "",
        duration: "",
        ringDuration: "",
        endTime: "",
        callStartTime: "",
      });
      setCancel(false);
      setLoaderValue("");
      setOpenedLeadDetail(dispatch, "OPENED_LEAD_DETAIL", res.data.lead);
      setLead(res.data.lead);
      handleShowNotPickedCallModal();
    } catch (error) {
      console.log(error);
    }
  };

  const updateCallFunction = async () => {
    try {
      setEndCallLoader(true);
      const { _id, calls, ...leadData } = lead;
      const date = new Date(Date.now() + 4 * 60 * 60 * 1000);
      const nextCallDateTime = await nextCallDateTimeSetter(date);
      const res = await updateLead(
        {
          lead: {
            _id,
            ...leadData,
            calls: [
              ...calls,
              {
                sequence: calls.length,
                result: "Picked",
                startTime: activeCallRemark.startTime,
                remark: activeCallRemark.remark,
                duration: activeCallRemark.duration,
                ringDuration: activeCallRemark.ringDuration,
                endTime: activeCallRemark.endTime,
              },
            ],
            nextCall: nextCallDateTime,
          },
        },
        token
      );
      setActiveCallRemark(dispatch, "ACTIVE_CALL_REMARK", {
        result: "",
        startTime: "",
        remark: "",
        duration: "",
        ringDuration: "",
        endTime: "",
        callStartTime: "",
      });
      setOpenedLeadDetail(dispatch, "OPENED_LEAD_DETAIL", res.data.lead);
      setLead(res.data.lead);
      setSubmit(false);
      setEndCallLoader(false);
      updateLeadNextCall(null, res.data.lead);
      handleShowNextCallModal();
    } catch (error) {
      console.log(error);
    }
  };

  const handleIncomingButtonClick = () => {
    try {
      callTimer(0, 0, setCallingTimer, dispatch, lead, callingTimer);
      setActiveCallRemark(dispatch, "ACTIVE_CALL_REMARK", {
        ...activeCallRemark,
        ringDuration: "",
        callStartTime: Date.now(),
        startTime: Date.now(),
      });
      setPicked(true);
      setRinging(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  const actionButtonStyles = {
    "& .material-icons-round": {
      transform: `translateX(0)`,
      transition: "all 200ms cubic-bezier(0.34,1.61,0.7,1.3)",
    },

    "&:hover .material-icons-round, &:focus .material-icons-round": {
      transform: `translateX(4px)`,
    },
  };

  return (
    <MDBox
      display="flex"
      justifyContent="space-between"
      m={1}
      sx={{
        top: "1%",
        position: "sticky",
        cursor: "pointer",
        zIndex: 999,
        backgroundColor: "#7b809a",
        borderRadius: "10px",
        alignItems: "center",
      }}
    >
      {showNotPickedModal && (
        <Modal
          open={showNotPickedModal}
          onClose={handleShowNotPickedCallModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropProps={{
            onClick: (event) => event.stopPropagation(),
          }}
          style={{
            width: "60%",
            margin: "auto",
            height: "fit-content",
          }}
        >
          <Grid
            container
            sx={{
              backgroundColor: "#495361",
              borderRadius: "10px",
              display: "flex",
              justifyContent: "center",
              padding: "10px 5px ",
            }}
          >
            {NOT_PICKED_RESPONSE.map((value, i) => {
              return (
                <Grid
                  item
                  xs={6}
                  sm={4}
                  lg={2}
                  key={value}
                  style={{
                    margin: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#CED4DA",
                    borderRadius: "10px",
                  }}
                  onClick={() => notFickedUpdateFun(value)}
                >
                  {loaderValue === value ? (
                    <ColorRing
                      visible={true}
                      height="20"
                      width="20"
                      ariaLabel="color-ring-loading"
                      wrapperStyle={{}}
                      wrapperClass="color-ring-wrapper"
                      colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
                    />
                  ) : (
                    <MDTypography
                      component="a"
                      href="#"
                      variant="button"
                      color="dark"
                      fontWeight="regular"
                      sx={actionButtonStyles}
                      style={{
                        margin: "0px 5px",
                        textAlign: "justify",
                      }}
                    >
                      {value}
                    </MDTypography>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </Modal>
      )}
      {showNextCallModal && (
        <Modal
          open={showNextCallModal}
          onClose={handleShowNextCallModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropProps={{
            onClick: (event) => event.stopPropagation(),
          }}
        >
          <Calendar
            initialView="dayGridMonth"
            initialDate={Date.now()}
            events={[]}
            selectable
            editable
            updateLeadNextCall={updateLeadNextCall}
            header={{ title: "select Next Call date time" }}
            weekends={false}
            onClose={handleShowNextCallModal}
          />
        </Modal>
      )}
      <MDBox
        style={{
          display: "flex",
          marginLeft: "auto",
          alignItems: "center",
        }}
      >
        <MDBox>
          <MDTypography variant="h5" mr={3} color="light">
            {lead.phone}
          </MDTypography>
        </MDBox>
        <MDBox display="flex" alignItems="center">
          {ringing ? (
            <>
              <MDTypography variant="h5" mr={3} color="dark">
                Ringing...
              </MDTypography>
              {!picked && (
                <MDButton
                  variant="gradient"
                  color="error"
                  onClick={() => callNotPicked()}
                  sx={{ marginRight: "20px" }}
                >
                  Not Picked
                </MDButton>
              )}
              <MDButton variant="gradient" color={"success"} onClick={handleCallButtonClick}>
                Picked
              </MDButton>
            </>
          ) : (
            <>
              {submit ? (
                <MDButton variant="gradient" color="success" onClick={updateCallFunction}>
                  {endCallLoader ? (
                    <ColorRing
                      visible={true}
                      height="20"
                      width="30"
                      ariaLabel="color-ring-loading"
                      wrapperStyle={{}}
                      wrapperClass="color-ring-wrapper"
                      colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
                    />
                  ) : (
                    "Update"
                  )}
                </MDButton>
              ) : !picked && cancel ? (
                <MDButton variant="gradient" color="warning" onClick={handleShowNotPickedCallModal}>
                  Cancel
                </MDButton>
              ) : !picked && !cancel ? (
                <MDButton
                  variant="gradient"
                  color="success"
                  onClick={() => handleCallButtonClick(true)}
                >
                  Call
                </MDButton>
              ) : (
                <>
                  <MDTypography variant="h5" mr={3} color="dark">
                    {callingTimer.time}
                  </MDTypography>
                  <MDButton variant="gradient" color="error" onClick={() => endCall()}>
                    End call
                  </MDButton>
                </>
              )}
            </>
          )}
          {!ringing && !picked && !cancel && !submit && (
            <MDBox display="flex" m={1}>
              <MDButton
                variant="gradient"
                color="warning"
                sx={{
                  fontWeight: ({ typography: { fontWeightBold } }) => fontWeightBold,
                  cursor: "pointer",
                  marginLeft: "auto",
                }}
                onClick={() => handleIncomingButtonClick()}
              >
                incomming
              </MDButton>
            </MDBox>
          )}
          {!ringing && (
            <MDBox display="flex" m={1}>
              <MDButton
                variant="gradient"
                color="error"
                sx={{
                  fontWeight: ({ typography: { fontWeightBold } }) => fontWeightBold,
                  cursor: "pointer",
                  marginLeft: "auto",
                }}
                onClick={() => close()}
              >
                close
              </MDButton>
            </MDBox>
          )}
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default CallingButtons;
