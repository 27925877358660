import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { useNavigate } from "react-router-dom";
import {
  useMaterialUIController,
  setOpenedEmail,
  setDemoSchadule,
  setFollowups,
  setNotPicked,
  setNewLeads,
} from "context";
import LeadModal from "layouts/modals/LeadModal";
import { getTodayDemos } from "apis/lead";
import { getFollowUps } from "apis/lead";
import { getNotPicked } from "apis/lead";
import { getNewLeadCount } from "apis/lead";
import { getLeadsWithCompletedFees } from "apis/lead";
import { setLeadsWithCompletedFees } from "context";
import { getLeadsWithPendingFees } from "apis/lead";
import { setLeadsWithPendingFees } from "context";
import { getOldFollowUps } from "apis/lead";
import { setOldFollowUps } from "context";

function Analytics() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    demoSchadule,
    followups,
    newLeads,
    notPicked,
    leadsWithCompletedFees,
    leadsWithPendingFees,
    oldFollowUps,
  } = controller;
  const [showLeadModal, setShowLeadModal] = useState(false);
  const [token] = useState(localStorage.getItem("user-secret") || null);
  const [refreshLead, steRefreshLead] = useState("");
  const navigate = useNavigate();

  const handleNavigation = (route, leadType, heading) => {
    navigate(route, { state: { leadType, heading } });
  };

  const handleNewLeads = () => {
    handleModal();
  };

  const handleModal = (lead) => {
    if (lead) {
      setOpenedEmail(dispatch, "OPENED_EMAIL", "");
    }
    if (showLeadModal) {
      steRefreshLead(Date.now());
    }
    setShowLeadModal(!showLeadModal);
  };

  async function fetchDemos() {
    const response = await getTodayDemos(token, 100);
    if (response && response.status === 200) {
      setDemoSchadule(dispatch, "DEMOSCHEDULE", response?.data.total);
    } else {
      console.log(response.error.message);
    }
  }

  async function fetchFollowUps() {
    const response = await getFollowUps(token, 100);
    if (response && response.status === 200) {
      setFollowups(dispatch, "FOLLOWUPS", response?.data.total);
    } else {
      console.log(response.error.message);
    }
  }

  async function fetchNotPicked() {
    const response = await getNotPicked(token, 100);
    if (response && response.status === 200) {
      setNotPicked(dispatch, "NOT_PICKED", response?.data.total);
    } else {
      console.log(response.error.message);
    }
  }

  async function fetchNewLeadCount() {
    const response = await getNewLeadCount(token, 100);
    if (response && response.status === 200) {
      setNewLeads(dispatch, "NEW_LEADS", response?.data.leadCount);
    } else {
      console.log(response.error.message);
    }
  }

  async function getLeadsByCompletedFees() {
    const response = await getLeadsWithCompletedFees(token, 100);
    if (response && response.status === 200) {
      setLeadsWithCompletedFees(dispatch, "LEADS_WITH_COMPLETED_FEES", response?.data.total);
    } else {
      console.log(response.error.message);
    }
  }

  async function getLeadsByPendingFees() {
    const response = await getLeadsWithPendingFees(token, 100);
    if (response && response.status === 200) {
      setLeadsWithPendingFees(dispatch, "LEADS_WITH_PENDING_FEES", response?.data.total);
    } else {
      console.log(response.error.message);
    }
  }

  async function fetchOldFollowUps() {
    const response = await getOldFollowUps(token, 100);
    if (response && response.status === 200) {
      setOldFollowUps(dispatch, "OLD_FOLLOW_UPS", response?.data.total);
    } else {
      console.log(response.error.message);
    }
  }

  useEffect(() => {
    if (token) {
      fetchDemos();
      fetchFollowUps();
      fetchNotPicked();
      fetchNewLeadCount();
      getLeadsByCompletedFees();
      getLeadsByPendingFees();
      fetchOldFollowUps();
    }
  }, [token, refreshLead]);

  return (
    <>
      {showLeadModal && <LeadModal open={showLeadModal} handleModal={handleModal} />}
      <MDBox py={3}>
        <MDBox mt={1.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="today"
                  title="Today's Demos"
                  handleClick={() => handleNavigation("/leads", "DEMOSCHEDULE", "Today's Demos")}
                  count={!demoSchadule ? "0" : demoSchadule || "- -"}
                  percentage={{
                    color: "success",
                    amount: "+55%",
                    label: "than lask week",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="phone_in_talk"
                  title="Follow Ups"
                  count={!followups ? "0" : followups || "- -"}
                  handleClick={() => handleNavigation("/leads", "FOLLOWUPS", "Follow Ups")}
                  percentage={{
                    color: "success",
                    amount: "+3%",
                    label: "than last month",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="person_add"
                  title="New Lead"
                  count={newLeads === 0 ? "0" : newLeads || "- -"}
                  handleClick={() => (newLeads === 0 ? alert("No New Lead") : handleNewLeads())}
                  percentage={{
                    color: "success",
                    amount: "+1%",
                    label: "than yesterday",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="phone_in_talk"
                  title="Not Picked"
                  count={!notPicked === 0 ? "0" : notPicked || "- -"}
                  handleClick={() => handleNavigation("/leads", "NOT_PICKED", "Not Picked Leads")}
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "Just updated",
                  }}
                />
              </MDBox>
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="info"
                  icon="phone_in_talk"
                  title="Old Follow Ups"
                  count={!oldFollowUps ? "0" : oldFollowUps || "- -"}
                  handleClick={() => handleNavigation("/leads", "OLD_FOLLOW_UPS", "Old Follow Ups")}
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "Just updated",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="phone_in_talk"
                  title="Pending Fees"
                  count={!leadsWithPendingFees ? "0" : leadsWithPendingFees || "- -"}
                  handleClick={() =>
                    handleNavigation("/leads", "LEADS_WITH_PENDING_FEES", "Leads with pending fees")
                  }
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "Just updated",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="phone_in_talk"
                  title="Completed Fees"
                  count={!leadsWithCompletedFees ? "0" : leadsWithCompletedFees || "- -"}
                  handleClick={() =>
                    handleNavigation(
                      "/leads",
                      "LEADS_WITH_COMPLETED_FEES",
                      "Leads with completed fees"
                    )
                  }
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "Just updated",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="today"
                  title="Total sales"
                  count={[]?.length === 0 ? "0" : []?.length || "- -"}
                  handleClick={() => {}}
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "Just updated",
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </>
  );
}

export default Analytics;
