import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Icon } from "@mui/material";

function ProductCell({ icon, name, total,color }) {
  return (
    <MDBox display="flex" alignItems="center" pr={2}>
      <MDBox
        variant="gradient"
        bgColor={color}
        color={"white"}
        coloredShadow={color}
        borderRadius="xl"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="3rem"
        height="3rem"
        style={{ cursor: "pointer" }}
        mr={1}
      >
        <Icon fontSize="medium" color="inherit">
          {icon}
        </Icon>
      </MDBox>
      <MDBox display="flex" flexDirection="column">
        <MDTypography variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        {/* <MDTypography variant="button" fontWeight="regular" color="secondary">
          <MDTypography component="span" variant="button" fontWeight="regular" color="success">
            {total}
          </MDTypography>{" "}
          calls
        </MDTypography> */}
      </MDBox>
    </MDBox>
  );
}

// Typechecking props for the ProductCell
ProductCell.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  orders: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default ProductCell;
