export const nextCallDateTimeSetter = (date) => {
  try {
    let nextCallTime = null;

    let utcDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    let utcHour = utcDate.getUTCHours();

    if (utcHour >= 19) {
      utcDate.setDate(utcDate.getDate() + 1);
      utcDate.setUTCHours(10, 0, 0, 0);
      nextCallTime = utcDate.toJSON();
    } else {
      nextCallTime = utcDate.toJSON();
    }
    return nextCallTime;
  } catch (error) {
    console.log(error);
    return "N/A";
  }
};
