export function timeCalculator(startDate, endDate) {
  var difference = endDate - startDate;
  var days = Math.floor(difference / (1000 * 60 * 60 * 24));
  var hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((difference % (1000 * 60)) / 1000);

  var result = "";
  if (days !== 0) {
    result += `${days} days, `;
  }
  if (hours !== 0) {
    result += `${hours} hours, `;
  }
  if (minutes !== 0) {
    result += `${minutes} minutes, `;
  }
  if (seconds !== 0) {
    result += `${seconds} seconds`;
  }

  result = result.trim().replace(/,\s*$/, "");

  return result;
}
