import Details from "layouts/leads/Details";

const { Modal, Box } = require("@mui/material");

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  maxHeight: "90vh",
  width: "80%",
  overflowY: "auto",
  p: 2,
  borderRadius: 3,
  "&::-webkit-scrollbar": {
    display: "none",
  },
};

const LeadModal = ({ open, handleModal, lead }) => {
  return (
    <Modal
      open={open}
      onClose={handleModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropProps={{
        onClick: (event) => event.stopPropagation(),
      }}
    >
      <Box sx={style}>
        <Details close={handleModal} leadOpened={lead} />
      </Box>
    </Modal>
  );
};

export default LeadModal;
