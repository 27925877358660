import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "layouts/pages/account/components/FormField";
import { COURSES } from "config";
import { GENDERS } from "config";
import { QUALIFICATIONS } from "config";
import { TextareaAutosize } from "@mui/material";
import { timeCalculator } from "utils/timeCalculator";
import { useMaterialUIController } from "context";
import { setActiveCallRemark } from "context";

function BasicInfo({ lead, setLead, token, nameSetter, updateLeadFun }) {
  const [controller, dispatch] = useMaterialUIController();
  const { activeCallRemark } = controller;

  return (
    <Card id="basic-infos" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Basic Info</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              label="First Name"
              placeholder={lead?.name?.split(" ")[0]}
              value={lead?.name?.split(" ")[0]}
              nameSetter={nameSetter}
              field="firstName"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Last Name"
              placeholder={
                lead?.name?.split(" ").slice(1, lead?.name?.split(" ").length).join(" ") || ""
              }
              value={lead?.name?.split(" ").slice(1, lead?.name?.split(" ").length).join(" ") || ""}
              nameSetter={nameSetter}
              field="lastName"
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  defaultValue={lead.gender}
                  options={GENDERS}
                  renderInput={(params) => (
                    <FormField
                      {...params}
                      label="Gender"
                      InputLabelProps={{ shrink: true }}
                      lead={lead}
                      setLead={setLead}
                      field="gender"
                      updateLeadFun={updateLeadFun}
                    />
                  )}
                  onChange={(e) => updateLeadFun(null, { ...lead, gender: e.target.innerHTML })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  defaultValue={lead.course || "Technical"}
                  options={COURSES}
                  renderInput={(params) => (
                    <FormField
                      {...params}
                      label="Course"
                      InputLabelProps={{ shrink: true }}
                      lead={lead}
                      setLead={setLead}
                      field="course"
                      updateLeadFun={updateLeadFun}
                    />
                  )}
                  onChange={(e) => updateLeadFun(null, { ...lead, course: e.target.innerHTML })}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Email"
              placeholder="example@email.com"
              inputProps={{ type: "email" }}
              value={lead.email}
              lead={lead}
              setLead={setLead}
              field="email"
              updateLeadFun={updateLeadFun}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Phone"
              placeholder="Phone Number"
              value={lead.phone}
              lead={lead}
              setLead={setLead}
              field="phone"
              updateLeadFun={updateLeadFun}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Fees"
              placeholder="Fees"
              value={lead.fees}
              lead={lead}
              setLead={setLead}
              field="fees"
              updateLeadFun={updateLeadFun}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              defaultValue={lead.qualification || "Technical"}
              options={QUALIFICATIONS}
              renderInput={(params) => (
                <FormField
                  {...params}
                  label="Qualification"
                  InputLabelProps={{ shrink: true }}
                  lead={lead}
                  setLead={setLead}
                  field="qualification"
                  updateLeadFun={updateLeadFun}
                />
              )}
              onChange={(e) => updateLeadFun(null, { ...lead, qualification: e.target.innerHTML })}
            />
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h5">Address</MDTypography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="City"
              placeholder="Sydney, A"
              value={lead.city}
              lead={lead}
              setLead={setLead}
              field="city"
              updateLeadFun={updateLeadFun}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="State"
              placeholder="Sydney, A"
              value={lead.state}
              lead={lead}
              setLead={setLead}
              field="state"
              updateLeadFun={updateLeadFun}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <MDTypography variant="h5">Remark</MDTypography>
            <TextareaAutosize
              minRows={5}
              style={{
                width: "100%",
                border: "1.3px #80808045 solid",
                borderRadius: 5,
              }}
              value={activeCallRemark.remark}
              onChange={(e) => {
                setActiveCallRemark(dispatch, "ACTIVE_CALL_REMARK", {
                  ...activeCallRemark,
                  remark: e.target.value,
                });
              }}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default BasicInfo;
