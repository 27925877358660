import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import Analytics from "./analytics";
import Footer from "examples/Footer";
import Details from "./Details";

const Dashboard = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Analytics />
      <Details />
      <Footer />
    </DashboardLayout>
  );
};

export default Dashboard;
