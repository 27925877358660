import { Card } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import SoucreCard from "./Source/SourceCard";

const Source = ({ lead }) => {
  return (
    <Card id="source">
      <SoucreCard
        title="Source"
        description="   "
        info={{
          from: lead.source === "ig" ? "Instagram" : lead.source === "fb" ? "Facebook" : "N/A",
          campaignName: lead.campaign_name,
          adName: lead.ad_name,
          adsetName: lead.adset_name,
          formName: lead.form_name,
          type: lead.paid ? "paid" : "organic",
          leadCost: "00",
        }}
        social={[
          {
            link: "https://www.facebook.com/CreativeTim/",
            icon:
              lead.source === "ig" ? (
                <InstagramIcon />
              ) : lead.source === "fb" ? (
                <FacebookIcon />
              ) : (
                <TwitterIcon />
              ),
            color: "facebook",
          },
        ]}
        action={{ route: "", tooltip: "Edit Profile" }}
        shadow={false}
      />
    </Card>
  );
};

export default Source;
