import { useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import { clearErrorMessage } from "utils/clearErrorMessage";
import { register } from "apis/user";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import UserManagementLayout from "examples/LayoutContainers/UserManageMentLayout";
import Footer from "examples/Footer";
import { ColorRing } from "react-loader-spinner";
import { Autocomplete } from "@mui/material";

function Cover() {
  const [user, setUser] = useState({ email: "", password: "", userName: "", organization: "" });
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [token] = useState(localStorage.getItem("user-secret") || null);

  const createNewuser = async () => {
    try {
      if (!user.email || !user.password || !user.userName || !user.organization) {
        setError("All fileds are required !");
      } else {
        setLoading(true);
        const response = await register(user, token);
        if (response.status === 200) {
          setUser({ email: "", password: "", userName: "", organization: "" });
          setMessage(response.data.message);
        } else {
          setError(response.error.message);
        }
      }
      setLoading(false);
      clearErrorMessage(3000, setError);
    } catch (e) {
      console.log("Error on createNewuser:", e.message);
    }
  };

  return (
    <UserManagementLayout>
      <DashboardNavbar />
      <CoverLayout image={bgImage}>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={3}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Create new user
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <MDInput
                  value={user.userName}
                  type="text"
                  label="Username"
                  variant="standard"
                  fullWidth
                  onChange={(e) => {
                    setUser({ ...user, userName: e.target.value });
                  }}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  value={user.email}
                  type="email"
                  label="Email"
                  variant="standard"
                  fullWidth
                  onChange={(e) => {
                    setUser({ ...user, email: e.target.value });
                  }}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  value={user.password}
                  type="password"
                  label="Password"
                  variant="standard"
                  fullWidth
                  onChange={(e) => {
                    setUser({ ...user, password: e.target.value });
                  }}
                />
              </MDBox>
              <MDBox mb={2} mt={2}>
                <MDTypography
                  component="label"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                >
                  Select organization
                </MDTypography>
                <Autocomplete
                  defaultValue={user.organization}
                  options={["Innobuzz", "Max"]}
                  onInputChange={(event, value) => setUser({ ...user, organization: value })}
                  renderInput={(params) => <MDInput {...params} variant="standard" />}
                />
              </MDBox>

              {error && (
                <MDBox>
                  <p style={{ color: "red", textAlign: "center" }}>{error}</p>
                </MDBox>
              )}
              {message && (
                <MDBox>
                  <p style={{ color: "green", textAlign: "center" }}>{message}</p>
                </MDBox>
              )}

              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="info" fullWidth onClick={() => createNewuser()}>
                  {loading ? (
                    <ColorRing
                      visible={true}
                      height="20"
                      width="30"
                      ariaLabel="color-ring-loading"
                      wrapperStyle={{}}
                      wrapperClass="color-ring-wrapper"
                      colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
                    />
                  ) : (
                    "create"
                  )}
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </CoverLayout>
      <Footer />
    </UserManagementLayout>
  );
}

export default Cover;
