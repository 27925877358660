import { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MDBox from "components/MDBox";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import routes from "routes";
import { useMaterialUIController, setMiniSidenav, setCallingTimer, setProfile } from "context";
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import MDSnackbar from "components/MDSnackbar";
import { ColorRing } from "react-loader-spinner";
import { callTimer } from "utils/callTimer";
import SignIn from "./layouts/authentication/sign-in/cover";
import { getProfile } from "apis/user";
import { auth } from "apis/lead";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    callingTimer,
    openedEmail,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const [token, setToken] = useState(localStorage.getItem("user-secret") || null);
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate();

  const getToken = async () => {
    try {
      setLoading(true);
      let user = localStorage.getItem("user-secret");
      if (user) {
        setToken(user);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getProfileDetails = async (token) => {
    try {
      const res = await getProfile(token);
      if (res.status === 200) {
        setProfile(dispatch, "PROFILE", res.data.user);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const checkAuth = async () => {
    try {
      const res = await auth(token);
      if (res.status === 200) {
        setAuthenticated(true);
        await getProfileDetails(token);
      } else {
        setAuthenticated(false);
        localStorage.clear();
      }
      setLoading(false);
    } catch (error) {
      setAuthenticated(false);
    }
  };

  useEffect(() => {
    !token && getToken();
    if (token) {
      checkAuth();
    }
  }, [token]);

  useEffect(() => {
    if (authenticated && pathname === "/sign-in") {
      navigate("/");
    }
  }, [authenticated, pathname]);

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        if (authenticated && route.route !== "/sign-in") {
          return <Route exact path={route.route} element={route.component} key={route.key} />;
        } else {
          return <Route exact path={"/sign-in"} element={<SignIn />} key={route.key} />;
        }
      }

      return null;
    });

  const callingTimerAction = () => {
    callTimer(0, 0, setCallingTimer, dispatch, {}, {}, true);
  };

  const renderOngoingCallModal = callingTimer?.time !== 0 && (
    <MDSnackbar
      icon="call"
      title={callingTimer.name}
      content="Ongoing..."
      dateTime={callingTimer?.time}
      open={true}
      onClose={() => {}}
      autoHide={false}
      close={() => {}}
      action={callingTimerAction}
    />
  );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      {loading ? (
        <MDBox display="flex" justifyContent="center" alignItems="center" height="100vh">
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="color-ring-loading"
            wrapperStyle={{}}
            wrapperClass="color-ring-wrapper"
            colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
          />
        </MDBox>
      ) : (
        <>
          <CssBaseline />
          {authenticated && (
            <>
              {layout !== "login" && (
                <>
                  <Sidenav
                    color={sidenavColor}
                    brand={
                      (transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite
                    }
                    brandName={`${localStorage.getItem("org")} CRM`}
                    routes={routes}
                    onMouseEnter={handleOnMouseEnter}
                    onMouseLeave={handleOnMouseLeave}
                    icon=""
                  />
                  <Configurator />
                  {callingTimer.email &&
                    callingTimer.email !== openedEmail &&
                    callingTimer.time !== 0 &&
                    renderOngoingCallModal}
                </>
              )}
            </>
          )}
          {layout === "vr" && <Configurator />}
          <Routes>
            {getRoutes(routes)}
            <Route path="*" element={<Navigate to={authenticated ? "/dashboard" : "/sign-in"} />} />
          </Routes>
        </>
      )}
    </ThemeProvider>
  );
}
