import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";
import { getNewLead, updateLead } from "apis/lead";
import Sidenav from "../components/Sidenav";
import Header from "../components/Header";
import BasicInfo from "../components/BasicInfo";
import Notifications from "../components/Notifications";
import Calls from "../components/Calls";
import BaseLayout from "../components/BaseLayout";
import {
  useMaterialUIController,
  setCallingTimer,
  setOpenedEmail,
  setOpenedLeadDetail,
} from "context";
import CallingButtons from "../components/CallingButtons";
import Source from "../components/Source";
import MDTypography from "components/MDTypography";
import Fees from "../components/Fees";
import { MONTHS_NAME } from "config";

function Details({ close, leadOpened }) {
  const location = useLocation();
  const [controller, dispatch] = useMaterialUIController();
  const { callingTimer } = controller;
  const state = location.state;
  const [lead, setLead] = useState(null);
  const [loading, setLoading] = useState(true);
  const [token] = useState(localStorage.getItem("user-secret"));
  const [activeSection, setActiveSection] = useState("basic-infos");
  const [error, setError] = useState("");

  const fetchNewLead = async () => {
    try {
      if (leadOpened) {
        setOpenedLeadDetail(dispatch, "OPENED_LEAD_DETAIL", leadOpened);
        setLead(leadOpened);
        setCallingTimer(dispatch, "CALLING_TIMER", {
          ...callingTimer,
          opnedLeadEmail: leadOpened.email,
        });
        setOpenedEmail(dispatch, "OPENED_EMAIL", leadOpened.email);
      } else {
        const response = await getNewLead(token);
        if (response.status === 200) {
          setLead(response.data.lead);
          setCallingTimer(dispatch, "CALLING_TIMER", {
            ...callingTimer,
            opnedLeadEmail: response.data.lead.email,
          });
          setOpenedEmail(dispatch, "OPENED_EMAIL", response.data.lead.email);
          setOpenedLeadDetail(dispatch, "OPENED_LEAD_DETAIL", response.data.lead);
        }
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const nameSetter = (value, field) => {
    if (field === "firstName") {
      setLead({
        ...lead,
        name: `${value} ${lead?.name
          ?.split(" ")
          .slice(1, lead?.name?.split(" ").length)
          .join(" ")}`,
      });

      updateLeadFun(null, {
        ...lead,
        name: `${value} ${lead?.name
          ?.split(" ")
          .slice(1, lead?.name?.split(" ").length)
          .join(" ")}`,
      });
    } else {
      setLead({
        ...lead,
        name: `${lead?.name?.split(" ")[0]} ${value}`,
      });
      updateLeadFun(null, {
        ...lead,
        name: `${lead?.name?.split(" ")[0]} ${value}`,
      });
    }
  };

  const updateLeadFun = async (stageName, updatedLead) => {
    try {
      let newUpdatedLead = "";
      newUpdatedLead = updatedLead;
      const response = await updateLead(
        {
          lead: {
            ...newUpdatedLead,
            stage:
              (stageName && stageName !== "Not Interested" && newUpdatedLead.previousStage) ||
              (stageName && stageName === "Not Interested" && stageName) ||
              newUpdatedLead.stage,
            previousStage: (stageName && newUpdatedLead.stage) || newUpdatedLead.previousStage,
          },
        },
        token
      );
      if (response.status === 200) {
        // setLead(response.data.lead);
        setError("");
      } else {
        setError("Lead not updated ");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateFeesLeadFun = async (field, value, newUpdate) => {
    try {
      const date = new Date();
      let emiStartMonth = lead?.feesStats?.EMI_start_month || "";
      let emiEndMonth = lead?.feesStats?.EMI_end_month || "";
      let isOnEmi = lead?.feesStats?.isOnEmis || false;
      let emiMonths = lead?.feesStats?.EMI_months || 0;
      let emiStats = newUpdate || lead?.feesStats?.EMI_stats || [];
      let installmentAmount = lead?.feesStats?.installmentAmount || 0;
      let balance = (lead?.feesStats?.discount || 0) - (lead?.feesStats?.collectedFees || 0);

      if (field === "collectedFees") {
        balance = lead?.feesStats?.discount - value;
      }
      if (field === "discount") {
        balance = value - (lead?.feesStats?.collectedFees || 0);
      }

      if (field === "EMI_months") {
        emiStats = [];
        emiStartMonth = MONTHS_NAME[date.getMonth()];
        const startIndex = MONTHS_NAME.indexOf(emiStartMonth);
        const lastMonthIndex = startIndex + (value - 1);
        emiEndMonth = MONTHS_NAME[lastMonthIndex];
        emiMonths = value;
        isOnEmi = true;
        let a = 1;
        let b = startIndex;
        installmentAmount = (lead?.feesStats?.discount / emiMonths).toFixed(2);

        while (a <= value) {
          if (b > MONTHS_NAME.length - 1) {
            b = 0;
          }
          emiStats.push({
            month: MONTHS_NAME[b],
            status: false,
            amount: (lead?.feesStats?.discount / emiMonths).toFixed(2),
            id: emiStats.length,
          });

          a++;
          b++;
        }
      }

      const newLead = {
        ...lead,
        feesStats: {
          ...lead.feesStats,
          [field]: value,
          EMI_start_month: emiStartMonth,
          EMI_end_month: emiEndMonth,
          isOnEmi,
          EMI_months: emiMonths,
          EMI_stats: emiStats,
          installmentAmount: installmentAmount,
          balance,
        },
      };
      setLead(newLead);
      const response = await updateLead(
        {
          lead: {
            ...newLead,
          },
        },
        token
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchNewLead();
  }, []);

  return (
    <>
      {loading ? (
        <MDBox display="flex" justifyContent="center">
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="color-ring-loading"
            wrapperStyle={{}}
            wrapperClass="color-ring-wrapper"
            colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
          />
        </MDBox>
      ) : (
        <>
          <CallingButtons
            close={close}
            lead={lead}
            token={token}
            setLead={setLead}
            updateLeadFun={updateLeadFun}
          />
          {error && (
            <MDBox display="flex" ml={1}>
              <MDTypography color={"error"} variant="h6">
                {error}
              </MDTypography>
              <MDTypography
                variant="h6"
                color="info"
                onClick={() => updateLeadFun()}
                sx={{ marginRight: "20px", cursor: "pointer", marginLeft: "10px" }}
              >
                Try again
              </MDTypography>
            </MDBox>
          )}
          <BaseLayout
            isNewLead={state ? true : false}
            lead={lead}
            setLead={setLead}
            token={token}
            close={close}
          >
            <MDBox mt={4}>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={4}>
                  <Sidenav setActiveSection={setActiveSection} />
                </Grid>
                <Grid item xs={12} lg={8}>
                  <MDBox mb={3}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Header lead={lead} setLead={setLead} updateLeadFun={updateLeadFun} />
                      </Grid>
                      <Grid item xs={12}>
                        {activeSection === "basic-infos" && (
                          <BasicInfo
                            lead={lead}
                            setLead={setLead}
                            nameSetter={nameSetter}
                            updateLeadFun={updateLeadFun}
                            token={token}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        {activeSection === "calls" && <Calls lead={lead} setLead={setLead} />}
                      </Grid>
                      <Grid item xs={12}>
                        {activeSection === "notifications" && (
                          <Notifications lead={lead} setLead={setLead} />
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        {activeSection === "source" && <Source lead={lead} setLead={setLead} />}
                      </Grid>
                      <Grid item xs={12}>
                        {activeSection === "fees" && (
                          <Fees
                            lead={lead}
                            setLead={setLead}
                            updateLeadfeesFun={updateFeesLeadFun}
                            updateLeadFun={updateLeadFun}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </BaseLayout>
        </>
      )}
    </>
  );
}

export default Details;
