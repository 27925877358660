import { updateLead } from "apis/lead";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useState } from "react";
import { ColorRing } from "react-loader-spinner";
import { clearErrorMessage } from "utils/clearErrorMessage";

const { Modal, Box, Autocomplete } = require("@mui/material");

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  maxHeight: "90vh",
  width: "50%",
  overflowY: "auto",
  py: 5,
  px: 4,
  borderRadius: 3,
  "&::-webkit-scrollbar": {
    display: "none",
  },
};

const LeadOwnerChangeModal = ({ open, handleModal, updateOwnerLead, setUpdateOwnerLead }) => {
  const [lead, setLead] = useState(updateOwnerLead || null);
  const [loader, setLoader] = useState(false);
  const [token] = useState(localStorage.getItem("user-secret") || null);
  const [error, setError] = useState("");

  const updateCallFunction = async () => {
    try {
      if (!lead.lead_owner) {
        setError("Please selcet lead owner");
      } else {
        setLoader(true);
        const response = await updateLead({ lead }, token);
        if (response.status === 200) {
          setUpdateOwnerLead(null);
          handleModal(null, true);
        } else {
          setError(response.error.message);
        }
        setLoader(false);
      }
      clearErrorMessage(3000, setError);
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropProps={{
        onClick: (event) => event.stopPropagation(),
      }}
    >
      <Box sx={style}>
        <MDBox display="flex" alignItems="center" justifyContent="end">
          <MDButton variant="text" color="error" onClick={() => handleModal()}>
            CLOSE
          </MDButton>
        </MDBox>
        <MDBox mb={2} mt={2}>
          <MDTypography
            component="label"
            variant="button"
            fontWeight="regular"
            color="text"
            textTransform="capitalize"
          >
            Select or enter lead owner
          </MDTypography>
          <Autocomplete
            defaultValue={lead?.lead_owner}
            options={["praveen.innobuzz@gmail.com", "ravi@innobuzz.in", "geetika@innobuzz.in"]}
            onInputChange={(event, value) => setLead({ ...lead, lead_owner: value })}
            renderInput={(params) => <MDInput {...params} variant="standard" />}
          />
        </MDBox>
        {error && (
          <MDBox>
            <p style={{ color: "red", textAlign: "center" }}>{error}</p>
          </MDBox>
        )}
        <MDBox display="flex" alignItems="center" justifyContent="end" mt={4}>
          <MDButton variant="gradient" color={"success"} onClick={() => updateCallFunction()}>
            {loader ? (
              <ColorRing
                visible={true}
                height="20"
                width="30"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
              />
            ) : (
              "Update"
            )}
          </MDButton>
        </MDBox>
      </Box>
    </Modal>
  );
};

export default LeadOwnerChangeModal;
