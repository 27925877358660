import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import LeadDetailCell from "layouts/dashboards/details/components/LeadDetailCell";
import DefaultCell from "layouts/dashboards/details/components/DefaultCell";
import DataTable from "examples/Tables/DataTable";
import { getTodayCalls } from "apis/lead";
import { getTotal } from "apis/lead";
import { NOT_PICKED_RESPONSE } from "config";
import { useMaterialUIController, setTodayCalls } from "context";

const Details = () => {
  const [token] = useState(localStorage.getItem("user-secret") || null);
  const [total, setTotal] = useState({
    totalLeads: null,
    totalDemos: null,
    totalNotInterested: null,
  });
  const [controller, dispatch] = useMaterialUIController();
  const { todayCalls } = controller;

  const todayTableData = {
    columns: [
      { Header: "", accessor: "title" },
      { Header: "", accessor: "value" },
    ],
    rows: [
      {
        title: (
          <LeadDetailCell
            icon={"phone_in_talk"}
            name="Not Picked"
            total={
              todayCalls.notPicked?.length && todayCalls.picked?.length
                ? todayCalls.notPicked?.length + todayCalls.picked?.length
                : 0
            }
            color={"error"}
          />
        ),
        value: (
          <DefaultCell>
            {todayCalls.notPicked?.length === 0 ? "0" : todayCalls.notPicked?.length || "- -"}
          </DefaultCell>
        ),
      },
      {
        title: (
          <LeadDetailCell
            icon={"phone_in_talk"}
            name="Picked"
            total={
              todayCalls.notPicked?.length && todayCalls.picked?.length
                ? todayCalls.notPicked?.length + todayCalls.picked?.length
                : 0
            }
            color={"success"}
          />
        ),
        value: (
          <DefaultCell>
            {todayCalls.picked?.length === 0 ? "0" : todayCalls.picked?.length || "- -"}
          </DefaultCell>
        ),
      },
      {
        title: (
          <LeadDetailCell
            icon={"plus"}
            name="Total"
            total={
              todayCalls.notPicked?.length && todayCalls.picked?.length
                ? todayCalls.notPicked?.length + todayCalls.picked?.length
                : 0
            }
            color={"success"}
          />
        ),
        value: (
          <DefaultCell>
            {todayCalls.notPicked?.length && todayCalls.picked?.length
              ? todayCalls.notPicked?.length + todayCalls.picked?.length
              : 0}
          </DefaultCell>
        ),
      },
    ],
  };

  const tableData = {
    columns: [
      { Header: "", accessor: "title" },
      { Header: "", accessor: "value" },
    ],
    rows: [
      {
        title: <LeadDetailCell icon={"today"} name="Total Leads" total={8.232} color={"dark"} />,
        value: (
          <DefaultCell>{total?.totalLeads === 0 ? "0" : total?.totalLeads || "- -"}</DefaultCell>
        ),
      },
      {
        title: <LeadDetailCell icon={"today"} name="Total Demos" total={8.232} color={"dark"} />,
        value: (
          <DefaultCell>{total?.totalDemos === 0 ? "0" : total?.totalDemos || "- -"}</DefaultCell>
        ),
      },
      {
        title: <LeadDetailCell icon={"today"} name="Not Interested" total={8.232} color={"dark"} />,
        value: (
          <DefaultCell>
            {total?.totalNotInterested === 0 ? "0" : total?.totalNotInterested || "- -"}
          </DefaultCell>
        ),
      },
    ],
  };

  async function fetchTodayCalls() {
    const response = await getTodayCalls(token);
    if (response && response.status === 200) {
      const todayPickedResponse = [];
      response?.data.leads.forEach((lead) => {
        lead.calls.forEach((call) => {
          const callStartTime = new Date(call.startTime);
          const today = new Date();
          const isToday =
            callStartTime.getDate() === today.getDate() &&
            callStartTime.getMonth() === today.getMonth() &&
            callStartTime.getFullYear() === today.getFullYear();
          if (isToday && call.result.toLowerCase() === "picked") {
            todayPickedResponse.push(lead);
          }
        });
      });
      const todayNotPickedResponse = [];
      response?.data.leads.forEach((lead) => {
        lead.calls.forEach((call) => {
          const callStartTime = new Date(call.startTime);
          const today = new Date();
          const isToday =
            callStartTime.getDate() === today.getDate() &&
            callStartTime.getMonth() === today.getMonth() &&
            callStartTime.getFullYear() === today.getFullYear();
          if (isToday && NOT_PICKED_RESPONSE.includes(call.result)) {
            todayNotPickedResponse.push(lead);
          }
        });
      });
      setTodayCalls(dispatch, "TODAY_CALL_DETAILS", {
        ...todayCalls,
        picked: todayPickedResponse,
        notPicked: todayNotPickedResponse,
        total: response?.data.leads,
      });
    } else {
      console.log(response.error.message);
    }
  }

  async function fetchTotal() {
    const response = await getTotal(token);
    if (response && response.status === 200) {
      setTotal({
        ...total,
        totalLeads: response?.data?.totalLeads,
        totalDemos: response?.data?.totalDemos,
        totalNotInterested: response?.data?.notInterested,
      });
    } else {
      console.log(response.error.message);
    }
  }

  useEffect(() => {
    if (token) {
      fetchTodayCalls();
      fetchTotal();
    }
  }, [token]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <MDBox pt={3} px={3}>
            <MDTypography variant="h6" fontWeight="medium">
              Today's Updates
            </MDTypography>
          </MDBox>
          <MDBox py={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <MDBox py={1}>
                    <DataTable
                      table={todayTableData}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      isSorted={false}
                      noEndBorder
                    />
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <MDBox pt={3} px={3}>
            <MDTypography variant="h6" fontWeight="medium">
              Updates
            </MDTypography>
          </MDBox>
          <MDBox py={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <MDBox py={1}>
                    <DataTable
                      table={tableData}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      isSorted={false}
                      noEndBorder
                    />
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </Grid>
      </Grid>
    </>
  );
};

export default Details;
