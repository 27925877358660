export const profileSetter = (name) => {
  try {
    if (!name) {
      return "NA";
    }
    let result = "";
    const splitName = name.split(" ");
    if (splitName.length > 1) {
      result = splitName[0].charAt(0) + splitName[splitName.length - 1].charAt(0);
    } else {
      result = splitName[0].charAt(0);
    }
    return result;
  } catch (error) {
    console.log(error);
    return "NA";
  }
};
