import ProfileOverview from "layouts/pages/profile/profile-overview";
import Settings from "layouts/pages/account/settings";
import MDAvatar from "components/MDAvatar";
import SignUp from "layouts/authentication/sign-up/cover";

import profilePicture from "assets/images/team-3.jpg";
import Leads from "layouts/leads/Leads";
import { Icon } from "@mui/material";
import Logout from "layouts/logout";
import { getProfile } from "apis/user";
import Dashboard from "layouts/dashboards/Dashboard";
import CreateLead from "layouts/leads/CreateLead";

const getUser = async () => {
  try {
    const token = localStorage.getItem("user-secret");
    if (token) {
      const res = await getProfile(token);
      if (res.status === 200) {
        localStorage.setItem("org", res.data.user.organization);
        return res.data.user;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (error) {
    console.log(error.message);
    return false;
  }
};

const user = await getUser();

const routes = [
  {
    type: "collapse",
    name: user.userName,
    key: user.userName,
    icon: (
      <MDAvatar
        src={user?.profile ? user?.profile : profilePicture}
        alt="Brooklyn Alice"
        size="sm"
      />
    ),
    collapse: [
      {
        name: "My Profile",
        key: "my-profile",
        route: "/pages/profile/profile-overview",
        component: <ProfileOverview />,
      },
      {
        name: "Settings",
        key: "profile-settings",
        route: "/pages/account/settings",
        component: <Settings />,
      },
      {
        name: "Logout",
        key: "logout",
        route: "/logout",
        component: <Logout />,
      },
    ],
  },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboards",
    component: <Dashboard />,
    icon: <Icon fontSize="medium">dashboard</Icon>,
    route: "/dashboard",
    noCollapse: true,
  },
  { type: "divider", key: "divider-1" },
  {
    type: "collapse",
    name: "Leads",
    key: "leads",
    title: "Pages",
    route: "/leads",
    component: <Leads />,
    noCollapse: true,
    icon: <Icon fontSize="medium">receipt_long</Icon>,
  },
  { type: "divider", key: "divider-2" },
  {
    type: "collapse",
    name: "Create Lead",
    key: "create lead",
    title: "Pages",
    route: "/create",
    component: <CreateLead />,
    noCollapse: true,
    icon: <Icon fontSize="medium">receipt_long</Icon>,
  },
  { type: "divider", key: "divider-3" },
];

if (user.isAdmin) {
  routes.push(
    { type: "title", title: "User management", key: "title-pages" },
    {
      type: "collapse",
      name: "New user",
      key: "sign-in",
      title: "Pages",
      route: "/create-user",
      component: <SignUp />,
      noCollapse: true,
      icon: <Icon fontSize="medium">add</Icon>,
    }
  );
}

export default routes;
