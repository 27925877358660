import axios from "axios";
import { BASE_URL, ENDPOINTS } from "../../../../client/src/config";

export const getLeads = async (token, limit) => {
  try {
    const response = await axios.get(`${BASE_URL}${ENDPOINTS.getLeads}?limit=${limit}`, {
      params: {
        "x-auth-token": token,
      },
    });
    return { status: response.status, data: response.data };
  } catch (error) {
    console.log("Error on /apis/lead/getLeads", error);
    return {
      status: error.response ? error.response.status : 500,
      error: error.response ? error.response.data : { message: "Network error" },
    };
  }
};

export const getNewLead = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}${ENDPOINTS.getNewLead}`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return { status: response.status, data: response.data };
  } catch (error) {
    console.log("Error on /apis/lead/getNewLead", error);
    return {
      status: error.response ? error.response.status : 500,
      error: error.response ? error.response.data : { message: "Network error" },
    };
  }
};

export const getTodayDemos = async (token, limit) => {
  try {
    const response = await axios.get(`${BASE_URL}${ENDPOINTS.getTodayDemos}?limit=${limit}`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return { status: response.status, data: response.data };
  } catch (error) {
    console.log("Error on /apis/lead/getTodayDemos", error);
    return {
      status: error.response ? error.response.status : 500,
      error: error.response ? error.response.data : { message: "Network error" },
    };
  }
};

export const getFollowUps = async (token, limit) => {
  try {
    const response = await axios.get(`${BASE_URL}${ENDPOINTS.getFollowUps}?limit=${limit}`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return { status: response.status, data: response.data };
  } catch (error) {
    console.log("Error on /apis/lead/getFollowUps", error);
    return {
      status: error.response ? error.response.status : 500,
      error: error.response ? error.response.data : { message: "Network error" },
    };
  }
};

export const updateLead = async (body, token) => {
  try {
    const response = await axios.post(`${BASE_URL}${ENDPOINTS.updateLead}`, body, {
      headers: {
        "x-auth-token": token,
      },
    });
    return { status: response.status, data: response.data };
  } catch (error) {
    console.log("Error on /apis/lead/updateLead", error);
    return {
      status: error.response ? error.response.status : 500,
      error: error.response ? error.response.data : { message: "Network error" },
    };
  }
};

export const getTodayCalls = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}${ENDPOINTS.getTodayCalls}`, {
      params: {
        "x-auth-token": token,
      },
    });
    return { status: response.status, data: response.data };
  } catch (error) {
    console.log("Error on /apis/lead/getTodayCalls", error);
    return {
      status: error.response ? error.response.status : 500,
      error: error.response ? error.response.data : { message: "Network error" },
    };
  }
};

export const getTotal = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}${ENDPOINTS.getTotal}`, {
      params: {
        "x-auth-token": token,
      },
    });
    return { status: response.status, data: response.data };
  } catch (error) {
    console.log("Error on /apis/lead/getTotal", error);
    return {
      status: error.response ? error.response.status : 500,
      error: error.response ? error.response.data : { message: "Network error" },
    };
  }
};

export const getNotPicked = async (token, limit) => {
  try {
    const response = await axios.get(`${BASE_URL}${ENDPOINTS.getNotPicked}?limit=${limit}`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return { status: response.status, data: response.data };
  } catch (error) {
    console.log("Error on /apis/lead/getNotPicked", error);
    return {
      status: error.response ? error.response.status : 500,
      error: error.response ? error.response.data : { message: "Network error" },
    };
  }
};

export const getNewLeadCount = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}${ENDPOINTS.getNewLeadCount}`, {
      params: {
        "x-auth-token": token,
      },
    });
    return { status: response.status, data: response.data };
  } catch (error) {
    console.log("Error on /apis/lead/getNewLeadCount", error);
    return {
      status: error.response ? error.response.status : 500,
      error: error.response ? error.response.data : { message: "Network error" },
    };
  }
};

export const auth = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}${ENDPOINTS.auth}`, {
      params: {
        "x-auth-token": token,
      },
    });
    return { status: response.status, data: response.data };
  } catch (error) {
    console.log("Error on /apis/lead/auth", error);
    return {
      status: error.response ? error.response.status : 500,
      error: error.response ? error.response.data : { message: "Network error" },
    };
  }
};

export const createLead = async (body, token) => {
  try {
    const response = await axios.post(`${BASE_URL}${ENDPOINTS.createLead}`, body, {
      headers: {
        "x-auth-token": token,
      },
    });
    return { status: response.status, data: response.data };
  } catch (error) {
    console.log("Error on /apis/lead/createLead", error);
    return {
      status: error.response ? error.response.status : 500,
      error: error.response ? error.response.data : { message: "Network error" },
    };
  }
};

export const getLeadsWithCompletedFees = async (token, limit) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${ENDPOINTS.getLeadsWithCompletedFees}?limit=${limit}`,
      {
        headers: {
          "x-auth-token": token,
        },
      }
    );
    return { status: response.status, data: response.data };
  } catch (error) {
    console.log("Error on /apis/lead/getLeadsWithCompletedFees", error);
    return {
      status: error.response ? error.response.status : 500,
      error: error.response ? error.response.data : { message: "Network error" },
    };
  }
};

export const getLeadsWithPendingFees = async (token, limit) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${ENDPOINTS.getLeadsWithPendingFees}?limit=${limit}`,
      {
        headers: {
          "x-auth-token": token,
        },
      }
    );
    return { status: response.status, data: response.data };
  } catch (error) {
    console.log("Error on /apis/lead/getLeadsWithPendingFees", error);
    return {
      status: error.response ? error.response.status : 500,
      error: error.response ? error.response.data : { message: "Network error" },
    };
  }
};

export const getOldFollowUps = async (token, limit) => {
  try {
    const response = await axios.get(`${BASE_URL}${ENDPOINTS.getOldFollowUps}?limit=${limit}`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return { status: response.status, data: response.data };
  } catch (error) {
    console.log("Error on /apis/lead/getOldFollowUps", error);
    return {
      status: error.response ? error.response.status : 500,
      error: error.response ? error.response.data : { message: "Network error" },
    };
  }
};
