import React, { useEffect, useState } from "react";
import { Card, Grid, Stack, Paper, styled } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import FormField from "layouts/pages/account/components/FormField";
import MDButton from "components/MDButton";
import LeadFeesEditModal from "layouts/modals/LeadFeesEditModal";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MONTHS_NAME } from "config";
import { dateFormatter } from "utils/dateFormatter";
import { updateLead } from "apis/lead";

const Fees = ({ lead, setLead, updateLeadfeesFun }) => {
  const [showModal, setShowModal] = useState(false);
  const [editMonth, setEditMonth] = useState("");
  const [token] = useState(localStorage.getItem("user-secret"));
  const [dummyState, setDummyState] = useState(0); // Dummy state for force update

  const DemoPaper = styled(Paper)(({ theme }) => ({
    width: "100%",
    height: "fit-content",
    padding: theme.spacing(2),
    ...theme.typography.body2,
    // textAlign: "center",
    display: "flex",
    justifyContent: "space-between",
  }));

  const updateFees = (month) => {
    lead.feesStats.EMI_stats = lead.feesStats.EMI_stats.map((old) => {
      if (old.id === month.id) {
        old.status = true;
        old.paymentDate = Date.now();
        lead.feesStats.collectedFees =
          month.remainingAmount && month.nextPaymentDate
            ? (lead.feesStats.collectedFees || 0) + parseInt(month.remainingAmount)
            : month.remainingAmount && !month.nextPaymentDate
            ? lead.feesStats.collectedFees
            : (parseInt(lead.feesStats.collectedFees) || 0) + parseInt(month.amount);
        old.amount =
          month.remainingAmount && !month.nextPaymentDate
            ? month.amount
            : month.remainingAmount
            ? (parseInt(month.amount) + parseInt(month.remainingAmount)).toFixed(2)
            : old.amount;
        old.nextPaymentDate = null;
        old.remainingAmount = null;
        return old;
      } else {
        return old;
      }
    });
    updateLeadfeesFun(lead.feesStats.EMI_stats);
  };

  const handleModal = (month) => {
    setShowModal(!showModal);
    if (month) {
      setEditMonth(month);
    }
  };

  const handleDateClick = async (pickedDateTime) => {
    const date = new Date(pickedDateTime.$d);
    let year = date.getFullYear();
    let day = date.getDate();
    const startIndex = MONTHS_NAME.indexOf(lead.feesStats.EMI_stats[0].month);
    let a = 1;
    let b = startIndex;

    for (let i = 0; i < lead.feesStats.EMI_stats.length; i++) {
      if (b > MONTHS_NAME.length - 1) {
        b = 0;
        year++;
      }
      lead.feesStats.EMI_stats[i].dueDate = new Date(
        year,
        MONTHS_NAME.indexOf(lead.feesStats.EMI_stats[i].month),
        day
      );
      a++;
      b++;
    }
    const response = await updateLead({ lead }, token);
    if (response.status === 200) {
      setLead(lead);
      setDummyState(dummyState + 1);
    } else {
    }
  };

  return (
    <Card id="fees" sx={{ overflow: "visible" }}>
      {showModal && editMonth && (
        <LeadFeesEditModal
          open={showModal}
          handleModal={handleModal}
          month={editMonth}
          lead={lead}
          setLead={setLead}
        />
      )}
      <MDBox p={3}>
        <MDTypography variant="h5">Fee records</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <Card sx={{ overflow: "visible", padding: 4 }}>
              <MDTypography textAlign="center">Total </MDTypography>
              <MDTypography textAlign="center"> {lead?.feesStats?.totalFees || 0}</MDTypography>
            </Card>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Card sx={{ overflow: "visible", padding: 4 }}>
              <MDTypography textAlign="center">Discount </MDTypography>
              <MDTypography textAlign="center"> {lead?.feesStats?.discount || 0}</MDTypography>
            </Card>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Card sx={{ overflow: "visible", padding: 4 }}>
              <MDTypography textAlign="center">Collected </MDTypography>
              <MDTypography textAlign="center"> {lead?.feesStats?.collectedFees || 0}</MDTypography>
            </Card>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Card sx={{ overflow: "visible", padding: 4 }}>
              <MDTypography textAlign="center">Balanced </MDTypography>
              <MDTypography textAlign="center">{lead?.feesStats?.balance || 0}</MDTypography>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Total Fees"
              placeholder={lead?.feesStats?.totalFees}
              value={lead?.feesStats?.totalFees || ""}
              lead={lead}
              setLead={setLead}
              field="totalFees"
              updateLeadfeesFun={updateLeadfeesFun}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Collected Fees"
              placeholder={lead?.feesStats?.collectedFees}
              value={lead?.feesStats?.collectedFees || ""}
              lead={lead}
              setLead={setLead}
              field="collectedFees"
              updateLeadfeesFun={updateLeadfeesFun}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Discount"
              placeholder={"discount"}
              value={lead?.feesStats?.discount || ""}
              setLead={setLead}
              field="discount"
              updateLeadfeesFun={updateLeadfeesFun}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="EMI Months"
              placeholder={"Enter the EMI months"}
              value={lead?.feesStats?.EMI_months || ""}
              setLead={setLead}
              field="EMI_months"
              updateLeadfeesFun={updateLeadfeesFun}
            />
          </Grid>
          {lead?.feesStats?.isOnEmi && (
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="Select EMI Date"
                  defaultValue={dayjs()}
                  onChange={(e) => handleDateClick(e)}
                />
              </LocalizationProvider>
            </Grid>
          )}

          {lead.feesStats.EMI_stats.map((month, i) => (
            <Grid item xs={12} key={month.id}>
              <Stack direction="row" spacing={2} alignItems="center">
                <DemoPaper
                  variant="outlined"
                  sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                >
                  <MDTypography variant="h6">{month.month}</MDTypography>
                  <MDTypography
                    variant="subtitle1"
                    style={{
                      background: month.status
                        ? "green"
                        : month.remainingAmount ||
                          (month.dueDate && new Date(month.dueDate) < Date.now())
                        ? "#b2b22b"
                        : "#565050a8",
                      fontSize: "10px",
                      height: "fit-content",
                      padding: "5px",
                      textAlign: "center",
                      color: "white",
                      borderRadius: 5,
                    }}
                  >
                    {month.status
                      ? "Payment completed"
                      : month.remainingAmount ||
                        (month.dueDate && new Date(month.dueDate) < Date.now())
                      ? "Payment due"
                      : "Upcomming payment"}
                  </MDTypography>
                  <MDTypography variant="body2">
                    {dateFormatter(month.paymentDate || month.dueDate, true)}
                  </MDTypography>
                  <MDBox>
                    <MDBox display="flex" alignItems="center">
                      <MDTypography variant="h6" color={month.status ? "success" : "error"}>
                        ₹ {month.remainingAmount ? month.remainingAmount : month.amount}
                      </MDTypography>
                      {!month.status && (
                        <>
                          <MDButton
                            variant="text"
                            color="success"
                            onClick={() => updateFees(month)}
                          >
                            Done
                          </MDButton>
                          <MDButton
                            variant="text"
                            color="warning"
                            onClick={() => handleModal(month)}
                          >
                            Edit
                          </MDButton>
                        </>
                      )}
                    </MDBox>
                    {month.remainingAmount && (
                      <MDBox display="flex" flexDirection="column">
                        {month.nextPaymentDate && (
                          <MDTypography variant="caption">
                            <span style={{ fontWeight: "bold" }}>nextPaymentDate: </span>
                            {dateFormatter(month.nextPaymentDate, true)}
                          </MDTypography>
                        )}
                        {month.remainingAmount && (
                          <MDTypography variant="caption" color="success">
                            <span style={{ fontWeight: "bold" }}>paid amount: </span>
                            {month.amount}
                          </MDTypography>
                        )}
                      </MDBox>
                    )}
                  </MDBox>
                </DemoPaper>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </MDBox>
    </Card>
  );
};

export default Fees;
