import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import Grid from "@mui/material/Grid";

function Call({ time, remark, result, color, duration, ringDuration }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <MDBox display="flex" alignItems="center" py={1}>
          <MDBox mr={2}>
            <MDButton variant="gradient" color={color} iconOnly circular>
              {result.split(" ").map((word) => word.charAt(0))}
            </MDButton>
          </MDBox>
          <MDBox display="flex" flexDirection="column">
            <MDBox display="flex" justifyContent="space-between" alignItems="center">
              <MDTypography variant="button" fontWeight="medium" gutterBottom>
                {new Date(time).toLocaleString()}
              </MDTypography>
            </MDBox>

            <MDTypography variant="caption" color="text" fontWeight="regular">
              {remark}
            </MDTypography>
          </MDBox>
        </MDBox>
      </Grid>
      <Grid item xs={12} sm={6}>
        <MDBox display="flex" flexDirection="column" alignItems="end" py={1}>
          <MDTypography variant="button" fontWeight="medium" gutterBottom mt={0}>
            {result.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
              return a.toUpperCase();
            })}
          </MDTypography>
          <MDTypography variant="caption" color="text" fontWeight="regular">
            {duration
              ? "Call duration " + duration
              : ringDuration
              ? "Ring duration " + ringDuration
              : "N/A"}
          </MDTypography>
        </MDBox>
      </Grid>
    </Grid>
  );
}

// Typechecking props of the Call
Call.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]).isRequired,
};

export default Call;
