import PropTypes from "prop-types";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers";

import Card from "@mui/material/Card";

import MDButton from "components/MDButton";
import { useState } from "react";
import dayjs from "dayjs";
import { Grid } from "@mui/material";

function Calendar({ header, updateLeadNextCall, onClose, ...rest }) {
  const [selectedDate, setSelectedDate] = useState("");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    maxHeight: "50vh",
    overflowY: "auto",
    p: 2,
    borderRadius: 3,
    "&::-webkit-scrollbar": {
      display: "none",
    },
  };

  const handleDateClick = (pickedDateTime) => {
    const date = new Date(pickedDateTime.$d);
    let utcDateString = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toJSON();
    setSelectedDate(utcDateString);
  };

  return (
    <Card sx={style}>
      <Grid item xs={12} display="flex" justifyContent="end">
        <MDButton variant="text" color="error" onClick={() => onClose()}>
          CLOSE
        </MDButton>
        <MDButton
          variant="text"
          color="success"
          onClick={() =>
            !selectedDate ? alert("Please select date") : updateLeadNextCall(selectedDate)
          }
        >
          DONE
        </MDButton>
      </Grid>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          label="next call date time"
          defaultValue={dayjs()}
          onChange={(e) => handleDateClick(e)}
        />
      </LocalizationProvider>
    </Card>
  );
}

// Setting default values for the props of Calendar
Calendar.defaultProps = {
  header: {
    title: "",
    date: "",
  },
};

// Typechecking props for the Calendar
Calendar.propTypes = {
  header: PropTypes.shape({
    title: PropTypes.string,
    date: PropTypes.string,
  }),
};

export default Calendar;
