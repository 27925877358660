function formatTime(hours, minutes, seconds, setCallingTimer, dispatch, lead, callingTimer) {
  let formattedTime = "";

  if (hours > 0) {
    formattedTime += hours < 10 ? "0" + hours + ":" : hours + ":";
  }

  formattedTime +=
    (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds);

  setCallingTimer(dispatch, "CALLING_TIMER", {
    email: lead.email,
    name: lead.name,
    time: formattedTime,  
  });
}

let interval = null;

export const callTimer = (
  startHour,
  startMinute,
  setCallingTimer,
  dispatch,
  lead,
  callingTimer,
  clearIntervalFlag
) => {
  try {
    let hours = startHour;
    let minutes = startMinute;
    let seconds = 0;

    if (clearIntervalFlag) {
      clearInterval(interval);
      setCallingTimer(dispatch, "CALLING_TIMER", {
        email: "",
        name: "",
        time: 0,
        opnedLeadEmail: "",
      });
    } else {
      interval = setInterval(() => {
        seconds++;
        if (seconds === 60) {
          seconds = 0;
          minutes++;
          if (minutes === 60) {
            minutes = 0;
            hours++;
            if (hours === 24) {
              hours = 0;
            }
          }
        }
        formatTime(hours, minutes, seconds, setCallingTimer, dispatch, lead, callingTimer);
      }, 1000);
    }
  } catch (error) {
    console.log(error);
  }
};
