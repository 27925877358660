import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/bg-sign-in-cover.jpeg";
import { clearErrorMessage } from "utils/clearErrorMessage";
import { login } from "apis/user";
import { ColorRing } from "react-loader-spinner";
import { useMaterialUIController, setLayout } from "context";
import Footer from "examples/Footer";

function Cover() {
  const [controller, dispatch] = useMaterialUIController();
  const [user, setUser] = useState({ email: "", password: "" });
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const { pathname } = useLocation();

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleSubmit = async () => {
    try {
      if (!user.email || !user.password) {
        setError("All fileds are required !");
      } else {
        setLoading(true);
        const response = await login(user);
        if (response.status === 200) {
          localStorage.setItem("user-secret", response.data.token);
          window.location.pathname = "/";
        } else {
          setError(response.error.message);
        }
      }
      setLoading(false);
      clearErrorMessage(3000, setError);
    } catch (error) {
      console.log("Error on handleSubmit:", error);
    }
  };

  useEffect(() => {
    setLayout(dispatch, "login");
  }, [pathname]);

  return (
    <>
      <CoverLayout image={bgImage}>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={3}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Sign in
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" my={1}>
              Enter your email and password to Sign In
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <MDInput
                  value={user.email}
                  type="email"
                  label="Email"
                  variant="standard"
                  fullWidth
                  placeholder="john@example.com"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    setUser({ ...user, email: e.target.value });
                  }}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  value={user.password}
                  type="password"
                  label="Password"
                  variant="standard"
                  fullWidth
                  placeholder="************"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    setUser({ ...user, password: e.target.value });
                  }}
                />
              </MDBox>
              <MDBox display="flex" alignItems="center" ml={-1}>
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  onClick={handleSetRememberMe}
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  &nbsp;&nbsp;Remember me
                </MDTypography>
              </MDBox>
              <MDBox>
                <p style={{ color: "red", textAlign: "center" }}>{error && error}</p>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="info" fullWidth onClick={() => handleSubmit()}>
                  {loading ? (
                    <ColorRing
                      visible={true}
                      height="20"
                      width="30"
                      ariaLabel="color-ring-loading"
                      wrapperStyle={{}}
                      wrapperClass="color-ring-wrapper"
                      colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
                    />
                  ) : (
                    "sign in"
                  )}
                </MDButton>
              </MDBox>
              {/* <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox> */}
            </MDBox>
          </MDBox>
        </Card>
      </CoverLayout>
      <Footer />
    </>
  );
}

export default Cover;
