import { createContext, useContext, useMemo, useReducer } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// The Material Dashboard 2 PRO React main context
const MaterialUI = createContext();

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "MaterialUIContext";

// Material Dashboard 2 PRO React reducer
function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "WHITE_SIDENAV": {
      return { ...state, whiteSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "DARKMODE": {
      return { ...state, darkMode: action.value };
    }
    case "DEMOSCHEDULE": {
      return { ...state, demoSchadule: action.value };
    }
    case "FOLLOWUPS": {
      return { ...state, followups: action.value };
    }
    case "NOT_PICKED": {
      return { ...state, notPicked: action.value };
    }
    case "LEADS_WITH_COMPLETED_FEES": {
      return { ...state, leadsWithCompletedFees: action.value };
    }
    case "LEADS_WITH_PENDING_FEES": {
      return { ...state, leadsWithPendingFees: action.value };
    }
    case "OLD_FOLLOW_UPS": {
      return { ...state, oldFollowUps: action.value };
    }
    case "CALLING_TIMER": {
      return { ...state, callingTimer: action.value };
    }
    case "OPENED_EMAIL": {
      return { ...state, openedEmail: action.value };
    }
    case "OPENED_LEAD_DETAIL": {
      return { ...state, openedLeadDetail: action.value };
    }
    case "ACTIVE_CALL_REMARK": {
      return { ...state, activeCallRemark: action.value };
    }
    case "PROFILE": {
      return { ...state, profile: action.value };
    }
    case "TODAY_CALL_DETAILS": {
      return { ...state, todayCalls: action.value };
    }
    case "NEW_LEADS": {
      return { ...state, newLeads: action.value };
    }
    case "LEADS": {
      return { ...state, leads: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Material Dashboard 2 PRO React context provider
function MaterialUIControllerProvider({ children }) {
  const initialState = {
    miniSidenav: false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: "info",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
    darkMode: false,
    demoSchadule: null,
    followups: null,
    notPicked: null,
    leadsWithCompletedFees: null,
    leadsWithPendingFees: null,
    oldFollowUps: null,
    fatchedLead: 0,
    callingTimer: { email: "", name: "", time: 0, opnedLeadEmail: "" },
    openedEmail: "",
    openedLeadDetail: "",
    activeCallRemark: {
      result: "",
      startTime: "",
      remark: "",
      duration: "",
      ringDuration: "",
      callStartTime: "",
      endTime: "",
    },
    profile: null,
    todayCalls: { picked: null, notPicked: null, total: null },
    newLeads: null,
    leads: null,
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

// Material Dashboard 2 PRO React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setWhiteSidenav = (dispatch, value) => dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });
const setDemoSchadule = (dispatch, type, value) => dispatch({ type, value });
const setFollowups = (dispatch, type, value) => dispatch({ type, value });
const setNotPicked = (dispatch, type, value) => dispatch({ type, value });
const setLeadsWithCompletedFees = (dispatch, type, value) => dispatch({ type, value });
const setLeadsWithPendingFees = (dispatch, type, value) => dispatch({ type, value });
const setOldFollowUps = (dispatch, type, value) => dispatch({ type, value });
const setCallingTimer = (dispatch, type, value) => dispatch({ type, value });
const setOpenedEmail = (dispatch, type, value) => dispatch({ type, value });
const setOpenedLeadDetail = (dispatch, type, value) => dispatch({ type, value });
const setActiveCallRemark = (dispatch, type, value) => dispatch({ type, value });
const setProfile = (dispatch, type, value) => dispatch({ type, value });
const setTodayCalls = (dispatch, type, value) => dispatch({ type, value });
const setNewLeads = (dispatch, type, value) => dispatch({ type, value });
const setLeads = (dispatch, type, value) => dispatch({ type, value });

export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
  setDemoSchadule,
  setFollowups,
  setNotPicked,
  setLeadsWithCompletedFees,
  setLeadsWithPendingFees,
  setOldFollowUps,
  setCallingTimer,
  setOpenedEmail,
  setOpenedLeadDetail,
  setActiveCallRemark,
  setProfile,
  setTodayCalls,
  setNewLeads,
  setLeads,
};
