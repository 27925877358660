import axios from "axios";
import { BASE_URL, ENDPOINTS } from "../../config";

export const register = async (body, token) => {
  try {
    const response = await axios.post(`${BASE_URL}${ENDPOINTS.signUp}`, body, {
      headers: {
        "x-auth-token": token,
      },
    });
    return { status: response.status, data: response.data };
  } catch (error) {
    console.log("Error on /apis/user/register", error);
    return {
      status: error.response ? error.response.status : 500,
      error: error.response ? error.response.data : { message: "Network error" },
    };
  }
};

export const login = async (body) => {
  try {
    const response = await axios.post(`${BASE_URL}${ENDPOINTS.signIn}`, body);
    return { status: response.status, data: response.data };
  } catch (error) {
    console.log("Error on /apis/user/login", error);
    return {
      status: error.response ? error.response.status : 500,
      error: error.response ? error.response.data : { message: "Network error" },
    };
  }
};

export const getProfile = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}${ENDPOINTS.getProfile}`, {
      params: {
        "x-auth-token": token,
      },
    });
    return { status: response.status, data: response.data };
  } catch (error) {
    console.log("Error on /apis/user/getProfile", error);
    return {
      status: error.response ? error.response.status : 500,
      error: error.response ? error.response.data : { message: "Network error" },
    };
  }
};
