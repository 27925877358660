import { useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import burceMars from "assets/images/bruce-mars.jpg";
import { PROFILE_COLORS } from "config";
import MDButton from "components/MDButton";
import { profileSetter } from "utils/profileSetter";

function Header({ lead, setLead, updateLeadFun }) {
  const [visible, setVisible] = useState(
    lead.stage.toLowerCase() === "not interested" ? true : false
  );

  const updateLeadStage = async () => {
    try {
      setVisible(!visible);
      setLead({
        ...lead,
        stage: !visible ? "Not Interested" : lead?.previousStage,
        previousStage: lead.stage,
      });
      updateLeadFun(!visible ? "Not Interested" : "previousStage", lead);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Card id="name-section">
      <MDBox p={2}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDButton
              variant="gradient"
              color={PROFILE_COLORS[Math.floor(Math.random() * PROFILE_COLORS.length)]}
              iconOnly
              circular
            >
              {profileSetter(lead.name)}
            </MDButton>
            {/* <MDAvatar src={burceMars} alt="profile-image" size="xl" shadow="sm" /> */}
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {lead.name}
              </MDTypography>
              <MDTypography
                variant="h6"
                color="text"
                fontWeight="medium"
                sx={{
                  fontSize: {
                    xs: "0.8rem",
                  },
                }}
              >
                {new Date(lead.created).toLocaleDateString() +
                  ", " +
                  new Date(lead.created).toLocaleTimeString()}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3} sx={{ ml: "auto" }}>
            <MDBox
              display="flex"
              justifyContent={{ md: "flex-end" }}
              alignItems="center"
              lineHeight={1}
            >
              <MDTypography variant="caption" fontWeight="regular">
                Not Interested
              </MDTypography>
              <MDBox ml={1}>
                <Switch checked={visible} onChange={updateLeadStage} />
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Header;
